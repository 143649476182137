window.resourceMentalHealthSpotCheck = function()
{
    return {
        currentStep: 0,
        happiness: 1,
        isHappyWithScore: null,
        howYouFeel: "",
        howYouMaintainHappiness: "",
        HowYouCouldRaiseScore: "",
        howYouCouldImproveHappiness: "",

        setStep: function(step){
            var element = document.querySelector("#resource-mental-health-spot-check");
            const y = element.getBoundingClientRect().top + window.scrollY;
            window.scroll({
              top: y - 96,
              behavior: 'smooth'
            });
            this.currentStep = step;
        },

        getHappyinessAsString: function(happiness){
            if (happiness <= 2){
                return 'unhappy';
            }
            if (happiness <= 4){
                return 'unsure';
            }
            if (happiness <= 7){
                return 'okay';
            }
            return 'happy';
        },
    };
};
