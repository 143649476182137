import Flickity from "flickity-fade";
import "flickity/css/flickity.css";
import "flickity-fade/flickity-fade.css";

// NOTE. Flickity executes itself on first load. Annoying! Something to note given swup.

const handler = {
    instances: [],

    /**
     * create()
     *
     * @return {Promise}
     */
    create(){
        return new Promise((resolve, reject) => {
            this.destroy().then(() => {

                try {

                    document.querySelectorAll("[data-flickity]").forEach(element => {

                        var flickity = Flickity.data(element);

                        if (flickity == null){
                            flickity = new Flickity(element, JSON.parse(element.getAttribute("data-flickity")));
                        }

                        this.instances.push({
                            element: element,
                            flickity: flickity,
                        });
                    });

                } catch (error){
                    console.error(error);
                    reject(error);
                }

                resolve();

            }).catch(reject);
        });
    },

    getInstance(element){
        element = typeof element == "string" ? document.querySelector(element) : element;
        return this.instances.find(instance => instance.element === element);
    },

    /**
     * Destroy()
     * @return {Promise}
     */
    destroy(){
        return new Promise((resolve, reject) => {

            try {

                this.instances.forEach(element => {
                    element.flickity.destroy();
                });

            } catch (error){
                console.error(error);
                reject(error);
            }

            this.instances = [];

            resolve();
        });
    },

    /**
     * swup()
     * Detect swup events and correctly restart animation detection
     * @param  {Object} swup
     * @return {Promise}
     */
    swup(swup){
        return new Promise((resolve, reject) => {
            try {
                swup.on("willReplaceContent", event => {
                    this.destroy();
                });
                swup.on("contentReplaced", event => {
                    this.create();
                });
                resolve();
            } catch (error){
                console.error(error);
                reject(error);
            }
        });
    },
}

window.FlickityHandler = handler;

export default handler;
