import Blueprint from "$blueprint/js/blueprint";
import Reveal from "$blueprint/js/reveal/index";
import Forms from "$blueprint/js/forms/index";
import Flickity from "$blueprint/js/flickity/index";

require("./alpine-components");

Blueprint().then(blueprint => {
    if (blueprint.isWebsite){

        Forms.create().then(Forms.swup(blueprint.swup));
        Flickity.create().then(Flickity.swup(blueprint.swup));
        Reveal.create().then(Reveal.swup(blueprint.swup));

        document.querySelectorAll("a, btn").forEach(element => {
            element.addEventListener("click", event => {
                setTimeout(() => {
                    event.target.blur();
                }, 100);
            });
        });
    }
});
